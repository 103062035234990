<template>
  <b-dropdown-item>
    <feather-icon
      v-if="item.status"
      icon="CloseIcon"
    />
    <feather-icon
      v-if="item.status"
      icon="ToggleRightIcon"
    />
    <feather-icon
      v-else
      icon="ToggleLeftIcon"
    />
    <span
      v-if="item.status"
      @click="deactivate()"
    > Reject </span>
    <span
      v-else
      @click="activate()"
    > Approve </span>
  </b-dropdown-item>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'ToggleStatus',
  props: {
    item: { type: Object, default: () => {} },
    activateUrl: { type: String, default: '' },
    deactivateUrl: { type: String, default: '' },
  },
  setup() {
    const { successfulOperationAlert, showErrors } = handleAlerts()
    return {
      successfulOperationAlert, showErrors,
    }
  },
  methods: {
    activate() {
      this.$entities.post(`${this.activateUrl}${this.item.id}`).then(() => {
        this.successfulOperationAlert('Activated Successfully')
        this.item.status = !this.item.status
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
    deactivate() {
      this.$entities.post(`${this.deactivateUrl}${this.item.id}`).then(() => {
        this.successfulOperationAlert('Deactivated Successfully')
        this.item.status = !this.item.status
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
  },
}
</script>
<style lang="scss">

</style>
